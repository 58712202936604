import React from 'react'
import './MemberCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'


const MemberCard = (props) => {
    return (
        <>
            <div className={`${props.flexDir ? "card_fd_fix " : ""}memb_car_card`}>
                <div className="memb_car_image">
                    <hr className="memb_car_line"></hr>
                    <img src={props.image}>
                    </img>
                    <hr></hr>
                </div>

                <div className="memb_car_content">
                    <h2>{props.name}</h2>
                    <h3>{props.year}</h3>
                    <h3>{props.position}</h3>

                    <div className="memb_car_icons_p">
                        <span className="memb_car_icons">
                            <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${props.fb_link}`}><FontAwesomeIcon icon={faEnvelope} /></a>
                        </span>
                        <span className="memb_car_icons">
                            <a href={props.ig_link}><FontAwesomeIcon icon={faInstagram} /></a>
                        </span>
                        <span className="memb_car_icons">
                            <a href={props.linked_link}><FontAwesomeIcon icon={faLinkedinIn} /></a>
                        </span>
                    </div>
                </div>

            </div>


        </>
    )
}

export default MemberCard
