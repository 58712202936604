import React from 'react'
import '../EventsPage/eventpage.css';
import './participation.css';
import gears from '../../assets/icons/gears.svg';
import Eventcard from '../EventCard';
import { maindata } from '../../maindata';
import HPVC from '../../assets/images/HPVC.png';
import SAUVC from '../../assets/images/SAUVC.png';
import SDC from '../../assets/images/SDC.png';
import TCTD from '../../assets/images/TCTD.png';
import cogni from '../../assets/images/cogni.png';
import IRC from '../../assets/images/IRC.png';
import Shrishti from '../../assets/images/Shrishti.png';
import Chanakya from '../../assets/images/Chanakya.png';

function ParticipationPage() {
    return (
        <React.Fragment>
            <div className="about_style">
                <h1>Participation</h1>
                <img className="gear_prop participation_gear" src={gears} />
                <Eventcard 
                    event_name={ maindata['HPVC']['head'] }
                    event_date={ maindata['HPVC']['date'] }
                    event_des={ maindata['HPVC']['about'] }
                    event_img={HPVC}
                    name='participation/HPVC'
                />
                <Eventcard 
                    event_name={ maindata['SAUVC']['head'] }
                    event_date={ maindata['SAUVC']['date'] }
                    event_des={ maindata['SAUVC']['about'] }
                    event_img={SAUVC}
                    name='participation/SAUVC'
                />
                <Eventcard 
                    event_name={ maindata['SDC']['head'] }
                    event_date={ maindata['SDC']['date'] }
                    event_des={ maindata['SDC']['about'] }
                    event_img={SDC}
                    name='participation/SDC'
                />
                <Eventcard 
                    event_name={ maindata['TCTD']['head'] }
                    event_date={ maindata['TCTD']['date'] }
                    event_des={ maindata['TCTD']['about'] }
                    event_img={TCTD}
                    name='participation/TCTD'
                />
                <Eventcard 
                    event_name={ maindata['cogni']['head'] }
                    event_date={ maindata['cogni']['date'] }
                    event_des={ maindata['cogni']['about'] }
                    event_img={cogni}
                    name='participation/cognizance'
                />
                <Eventcard 
                    event_name={ maindata['IRC']['head'] }
                    event_date={ maindata['IRC']['date'] }
                    event_des={ maindata['IRC']['about'] }
                    event_img={IRC}
                    name='participation/IRC'
                />
                <Eventcard 
                    event_name={ maindata['Shrishti']['head'] }
                    event_date={ maindata['Shrishti']['date'] }
                    event_des={ maindata['Shrishti']['about'] }
                    event_img={Shrishti}
                    name='participation/Shrishti'
                />
                <Eventcard 
                    event_name={ maindata['Chanakya']['head'] }
                    event_date={ maindata['Chanakya']['date'] }
                    event_des={ maindata['Chanakya']['about'] }
                    event_img={Chanakya}
                />
            </div>
        </React.Fragment>
    )
}

export default ParticipationPage
