import React, { useState } from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import group from './Group.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import arrow from './Group 1.svg'

const Footer = () => {
    const backtotop =()=>{
        window.scrollTo({
            top:0,
            left:0,
            behavior:'smooth'
        })
    }

    var emailURL = 'https://mail.google.com/mail/u/0/?fs=1&to=asmeiitr@iitr.ac.in&su=';
    const [mail, SetMail] = useState({
        fname: '',
        email: '',
        message: ''
    })
    function sendMail(e) {
        e.preventDefault();
        emailURL = emailURL+'Message%20from%20'+mail.fname+'&body='+mail.message+'%0DFrom%20'+mail.email+'&tf=cm';
        window.open(emailURL, '_blank');
        emailURL = 'https://mail.google.com/mail/u/0/?fs=1&to=asmeiitr@iitr.ac.in&su=';
    }
    function handle(e) {
        const newMail = {...mail};
        newMail[e.target.id] = e.target.value;
        SetMail(newMail);
        console.log(newMail);
    }

    return (
        <div>
            <div className="container">
                <div className="row2">
                    <div className="col1">
                        <div className="logo">
                            <h1 className="ASME">ASME</h1>
                            <h2 className="sub">IIT Roorkee Chapter</h2>
                        </div>
                        <div className="address">
                            <img src={group} className="web"></img><br />
                            Behind Advanced Composites<br />
                            Laboratory, MIED West Block, IIT<br />
                            Roorkee, 247667, Roorkee
                        </div>
                        <div className="icons4" id='contact_footer'>
                            <span className="icon1">
                            <a href="https://www.instagram.com/asmeiitr/" target='_blank'><FontAwesomeIcon icon={faInstagram} style={{color: 'white'}} /></a>
                            </span>
                            <span className="icon1">
                            <a href="https://www.facebook.com/asmeiitr" target='_blank'><FontAwesomeIcon icon={faFacebook} style={{color: 'white'}} /></a>
                            </span>
                            <span className="icon1">
                            <a href="https://in.linkedin.com/company/asmeiitr" target='_blank'><FontAwesomeIcon icon={faLinkedin} style={{color: 'white'}} /></a>
                            </span>
                        </div>
                    </div>
                    <div className="col2">
                        <h1 className="explore">
                            Explore
                        </h1>
                        <ul className="list">
                        <Link to="/participation"><li>Projects</li></Link>
                        <Link to="/blogs"><li>Blogs</li></Link>
                        <Link to="/events/workshops"><li>Workshops</li></Link>
                        <Link to="/events"><li>Events</li></Link>
                        <Link to="/resources"><li>Resources</li></Link>
                        <Link to="/gallery"><li>Gallery</li></Link>
                        </ul>
                    </div>
                    <div className="col3">
                        <h1 className="connect">Let's Connect!</h1>
                        <form className="form" onSubmit={(e) => sendMail(e)}>
                            <input onChange={(e) => handle(e)} type="text" id="fname" name="fname" placeholder="Name" required/>
                            <input onChange={(e) => handle(e)} type="email" id="email" name="email" placeholder="Email-Id" required/>
                            <input onChange={(e) => handle(e)} type="text" id="message" name="message" placeholder="Message" required/>
                            <div id="buttons">
                                <input type="reset" value="Clear" id="clear" />
                                <input type="submit" value="Submit" id="submit"/>
                            </div>
                        </form>
                    </div>
                </div>
                <img src={arrow} className='back_to_top' onClick={backtotop} />
            </div>
        </div>
    )
}

export default Footer

