import React, { useState } from 'react'
import { Link,useHistory,NavLink } from 'react-router-dom';
import './Navbar.css';
import { GiHamburgerMenu } from "react-icons/gi";
import asme_logo from '../../assets/images/asme_logo.png';


function Navabar() {
    const [ShowMediaIcons, setShowMediaIcons] = useState(false);
    const history = useHistory();
    return (
        <React.Fragment>
        <div className="navbar">
            <div className="left">

                <a style={{cursor:"pointer"}} onClick={()=>history.push('/')} ><img class="logo_prop" src={asme_logo} alt=""/></a>

            </div>
            <div className= {ShowMediaIcons ? "right mobile-right" : "right"}>
                <div className="mid-box_1"></div>
                <div className="mid-box_2"></div>
                <div className="main-box">
                    <ul className="nav-heading">
                        <NavLink activeStyle={{backgroundColor: "#348FE2"}} to="/aboutus" className="option">
                            <span >About Us</span>
                        </NavLink>
                        <NavLink activeStyle={{backgroundColor: "#348FE2"}} to="/events" className="option">
                            <span >Events</span>
                        </NavLink>
                        <NavLink activeStyle={{backgroundColor: "#348FE2"}} to="/participation" className="option">
                            <span >Participation</span>
                        </NavLink>
                        <NavLink activeStyle={{backgroundColor: "#348FE2"}} to="/blogs" className="option">
                            <span >Blogs</span>
                        </NavLink>
                        <NavLink activeStyle={{backgroundColor: "#348FE2"}} to="/gallery" className="option">
                            <span >Gallery</span>
                        </NavLink>
                        <NavLink activeStyle={{backgroundColor: "#348FE2"}} to="/resources" className="option">
                            <span >Resources</span>
                        </NavLink>
                        <NavLink activeStyle={{backgroundColor: "#348FE2"}} to="/team" className="option">
                            <span >Team</span>
                        </NavLink>
                        <NavLink activeStyle={{backgroundColor: "#348FE2"}} to="/contact" className="option">
                           <a href="#footer">Contact Us</a>
                        </NavLink>
                    </ul>
                </div>
            </div>
            <div className="hamburger-menu">
                <a href="#" onClick={() => setShowMediaIcons(!ShowMediaIcons)}>
                    <GiHamburgerMenu className="ham"/>
                </a>
            </div>
        </div>
        <div className="box"></div>
        </React.Fragment>
    )
}

export default Navabar;
