import React from 'react'
import '../../Events/events.css';
import './Shrishti.css';
import gears from '../../../assets/icons/gears.svg';
import { maindata } from '../../../maindata';
import shrishti_1 from '../../../assets/images/Shrishti.png';
import shrishti_2 from '../../../assets/images/Shrishti_2.png';

function Shrishti() {
    return (
        <React.Fragment>
        <div className="about_style">
            <h1>{ maindata['Shrishti']['head'] }</h1>

            <img className="event_gear_prop Shrishti" src={gears} />

            <p className="main_content">{ maindata['Shrishti']['about'] }</p><br />
            <div className='irc_image'>
                <img class="irc_2017" src={shrishti_1} alt=""/>
                <img class="irc_2017" src={shrishti_2} alt=""/>
            </div>
        </div>
        </React.Fragment>
    )
}

export default Shrishti