import i1 from './assets/images/about_image/20211104_192756 - square.jpg'
import i2 from './assets/images/about_image/Deshik Reddy - Technical Mentor.jpeg'
import i3 from './assets/images/about_image/sakshigupta.jpg'
import i4 from './assets/images/about_image/madhukar.jpg'
import p1 from './assets/team_data_images/Aditya Gupta.jpg'
import p2 from './assets/team_data_images/Aditya Raj.jpg'
import p3 from './assets/team_data_images/Aditya Sen.jpg'
import p4 from './assets/team_data_images/Aman Dhanotiya.jpg'
import p5 from './assets/team_data_images/Amichand Gurjar.jpg'
import p6 from './assets/team_data_images/Ankit Soni.jpg'
import p7 from './assets/team_data_images/Ashwani Kumar.jpg'
import p8 from './assets/team_data_images/Ashwin.jpg'
import p9 from './assets/team_data_images/Ayush Diwang.jpg'
import p10 from './assets/team_data_images/Bharat Singh Rajpurohit.jpg'
import p11 from './assets/team_data_images/Bhavesh Shamnani.jpg'
import p12 from './assets/team_data_images/Bhavna.jpg'
import p13 from './assets/team_data_images/Borate Srushti.jpg'
import p14 from './assets/team_data_images/Deepak Sharma.jpg'
import p15 from './assets/team_data_images/Deshik Reddy.jpg'
import p16 from './assets/team_data_images/DevRaj.jpg'
import p17 from './assets/team_data_images/Dhargawe Kishita.jpg'
import p18 from './assets/team_data_images/Dhruv Kumar.jpg'
import p19 from './assets/team_data_images/Dushyant Yadav.jpg'
import p20 from './assets/team_data_images/Harsh Raj Ambasta.jpg'
import p21 from './assets/team_data_images/Hemant Naresh.jpg'
import p22 from './assets/team_data_images/Janhavi.jpg'
import p23 from './assets/team_data_images/Kartikeya Ray.jpg'
import p24 from './assets/team_data_images/Lokendra.jpg'
import p25 from './assets/team_data_images/Madhukar.jpg'
import p26 from './assets/team_data_images/Mansi Paliwal.jpg'
import p27 from './assets/team_data_images/Mayank Kumar.jpg'
import p28 from './assets/team_data_images/Mundhe Sushant.jpg'
import p29 from './assets/team_data_images/Nitesh Kumar.jpg'
import p30 from './assets/team_data_images/Nitesh Morya.jpg'
import p31 from './assets/team_data_images/Omesh Kumawat.jpg'
import p32 from './assets/team_data_images/Pahul Singh Sawhney.jpg'
import p33 from './assets/team_data_images/Prajjwal.jpg'
import p34 from './assets/team_data_images/Sakshi Gupta.jpg'
import p35 from './assets/team_data_images/Shrashtika Singh.jpg'
import p36 from './assets/team_data_images/Soumyajeet.jpg'
import p37 from './assets/team_data_images/Sugandha Gupta.jpg'
import p38 from './assets/team_data_images/Tammana Rami.jpg'
import p39 from './assets/team_data_images/Uday.jpg'
import p40 from './assets/team_data_images/V Huzur Saran.jpg'
import p42 from './assets/team_data_images/Vaishnavi.jpg'
import p41 from './assets/team_data_images/Yash Srivastava.jpg'
import p43 from './assets/team_data_images/SarveshwarP.jpeg'


export const maindata = {
    'intro_info': 'The ASME Student Chapter at IIT Roorkee is an undergraduate club comprised of students majoring in various fields of Engineering. Our mission is to promote and enhance technical competency and professional well-being of our members through quality programs and activities in engineering.',
  
    'event_des': 'The ASME Student Chapter at IIT Roorkee is an undergraduate club comprised of students majoring in various fields of Engineering.The ASME Student Chapter at IIT Roorkee is an undergraduate club comprised of students majoring in various fields of Engineering.The ASME Student Chapter at IIT Roorkee is an undergraduate club comprised of students majoring in various fields of Engineering.The ASME Student Chapter at IIT Roorkee is an undergraduate club comprised of students majoring in various fields of Engineering.The ASME Student Chapter at IIT Roorkee is an undergraduate club comprised of students majoring in various fields of Engineering.',
    'AUV_Club': 'AUV is the autonomous underwater vehicle and it aims to promote underwater robotics in our institute as no such projects are being done till now. ASME is the first technical group on our campus that has created a Sister group named AUV club (Autonomous underwater vehicle). We are actually targeting some upcoming competitions like SAVe (Student autonomous underwater vehicle) organized by NIOT, Chennai, and SAUV (Singapore Autonomous underwater vehicle), Singapore. These two are fascinating competitions in the world of autonomous underwater vehicles.',
    'AUV_Line_Follower': 'Our team has built an AUV line follower to implement the subtask of line following underwater. Basically, we used image processing and some sensors like the depth sensor and IMU sensor to collect the information from the environment. We also simulate these sensors with software to record the data. The team created a mechanical model (cad model) using Solidworks, during the designing of this cad model we considered all aspects like its weight, volume, length, width, etc. according to our need.',
    'HPVC': {
        'head': 'Human Powered Vehicle Challenge (HPVC)',
        'date': '2021, 2020, 2019, 2018',
        'about': 'ASME\'s International Human Powered Vehicle Challenge (HPVC) provides an opportunity for students to bring sound engineering design principles to develop efficient, sustainable, fast, and practical transportation alternatives. This competition is organized every year as a part of E-fest by ASME Asia-Pacific. The event sees teams across the nation attempt to engineer a vehicle from scratch that is practical and eco-friendly.',
        '2020_head': 'HPVC, ASME E-Fest Asia Pacific, 2020',
        '2020_info': 'Around 50 teams were selected from all over India based on qualification reports on design to compete in the competition. HPVC 2020 comprises three events: Vehicle Design & Ergonomics, Speed (Men’s and Women’s drag race), and Endurance Race. We participated in these events for the fourth time in a row. Our overall rank was 4th this time. Event-wise, we stood 1st in the Design Event, 19th in the men’s speed event, 30th in the women’s speed event, and 10th in the endurance event. We improved significantly in comparison to our previous participation. Our overall rank improved from 32nd in 2018, 18th in 2019, to 4th in 2020.',
        '2021_head': 'HPVC, ASME E-Fest Global, 2021',
        '2021_info': 'In 2021, ASME and the ASME HPVC Committee hosted two independent worldwide competitions:  Critical Design Review (CDR) competition (online/digital) and  Innovation competition (online/digital). Our team participated in both competitions to get better exposure and experience in practical applications of engineering principles.',
        '2021_concept': 'In the Critical Design Review (CDR) competition, our objective was to demonstrate the practical application of established principles and practices of design engineering to develop the team’s vehicle without engaging in fabrication, assembly, or testing. And, in the Innovation Competition, we aimed to demonstrate significant technological innovations in human-powered vehicles.',
        '2021_info_2': 'In HPVC 2021, the team designed a human-powered vehicle using principles and practices of design engineering without actually engaging in actual manufacturing and testing as the competition was held virtually due to the pandemic. Our team is divided into five verticals: Frame, Suspension, Steering, Drivetrain, and Aerodynamics.',
        '2021_info_3': 'The frame vertical worked upon designing the frame with proper ergonomics and considering all the design constraints the competition’s organizing committee provided. Suspension vertical worked in collaboration with frame vertical to implement the front and rear suspension in the vehicle, considering all the pros and cons of different types of suspension. Drivetrain vertical aimed to design an efficient drivetrain system for the vehicle with minimum power loss and minimize the chain’s vibrations. Steering vertical designed an adjustable handlebar mechanism to accommodate riders of different heights, so the vehicle is not restricted to a rider of a particular height. Aerodynamics vertical designed an aerodynamic fairing for the vehicle with proper analysis of the drag coefficient for different design iterations.',
        '2021_info_4': 'In 2021 HPVC was a global competition that saw participation from many prestigious universities worldwide.'
    },
    'SAUVC': {
        'head': 'Singapore Autonomous Underwater Vehicle Challenge (SAUVC)',
        'date': '2022',
        'about': 'Autonomous underwater robotics is an exciting challenge in engineering, which participants get to experience at SAUVC. The SAUVC competition challenges participant teams to build an AUV (Autonomous Underwater Vehicle) to perform specific tasks. These tasks are simulations of tasks operational AUVs would have to be able to achieve. The competition is held in a swimming pool, and each team\'s AUV will have to perform four tasks. The speed and accuracy at which the AUV performs tasks will be used to decide the winner of the competition.',
        '2022_head': 'SAUVC 2022',
        '2022_info': 'Our team is currently developing an autonomous underwater vehicle intending to participate in SAUVC 2022. It is a great learning ground for the team to experience the challenges of AUV system engineering and develop skills in mechanical, electrical, and software engineering. We designed and prototyped a vehicle with six degrees of freedom to perform line following and target acquisition. For the vehicle’s structural integrity, we tested it using finite element methods and optimized the aerodynamic performance by using computational fluid dynamics analysis. Different simulations were run on the vehicle under a virtual environment to test the autonomous maneuverability of the vehicle inside water. Further work on target re-acquisition and acoustic positioning are to be done.',
        '2022_concept': 'The tasks involve four widely faced underwater challenges: AUV navigation, visual identification, acoustic localization, and robotic manipulation.',
        '2022_info_2': 'The team further worked on improving the current structural design of the underwater vehicle and the vehicle dynamics. Basic Topology Optimization was employed to reduce the overall weight of the frame. Also, a thorough analysis was done to carefully locate the center of mass and center of buoyancy to minimize the wobbling of the vehicle.',
        '2022_info_3': 'We used the Gazebo simulator, which is compatible with the framework ROS that had been used. The simulations were done for path planning in the given environment using 3D models and observed sensor feedback. The basic computer-vision-based object detection has been shifted to DL-based techniques as our SBC Nvidia jetson nano is capable of this.'
    },
    'SDC': {
        'head': 'Student Design Competition (SDC)',
        'date': '2020, 2019',
        'about': 'Students Design Challenge held at ASME E-Fest provides a unique design problem every year, which tests critical thinking as well as technical skills to solve the challenge. Each team is required to design, construct and operate a prototype meeting the requirements of an annually determined problem statement.',
        '2019_head': 'Student Design Competition, ASME E-Fest Asia Pacific, 2019',
        '2019_info': 'The 2019 SDC challenged creating a device that can quickly but carefully secure a variety of different balls that will be balancing on tube stands in the middle of a flat playing surface. We had to design and construct a single remotely controlled device to collect as many balls as possible, , place them in a collection area as fast as possible, and avoid having balls fall off their stand and hit the ground. In SDC 2019, our team secured the 4th position in the Asia-Pacific zone.',
        '2020_head': 'Student Design Competition, ASME E-Fest Asia Pacific, 2020',
        '2020_info': 'Teams were asked to design and manufacture a semi-autonomous machine of size (50cm X 50cm X 50cm) that can build a tower from standard A4-sized pages without any joining material such as adhesives or stapling pins. The teams were judged on three parameters, i.e., the maximum height of the tower, the speed at which the device builds the tower, and the maximum load the tower could sustain. The machine made by our team could reach a height of about 1.8 meters in 5 minutes and could sustain a weight of 6 kgs. The team was able to qualify for the semi-finals.'
    },
    'TCTD': {
        'head': 'TCTD Challenge, 7th Inter IIT Tech Meet, IIT Bombay',
        'date': '2018',
        'about': 'Tata Centre provided a problem Statement for Technology and Development (TCTD) at the 7th Inter IIT Meet held at IIT Bombay. Contingent teams were needed to identify problems around the central theme "Farm tools that reduce drudgery and provide intelligent automation", develop a technology-driven solution for them, the whole assembly, and demonstrate the prototype / Proof Of Concept in action.  Teams were allowed to focus on any agricultural activity which is labor-intensive and propose some technology-driven solution to solve it, prototype an instrument required for the activity, and if possible, demonstrate it.',
        '2018_info': 'Team designed and prototyped a “Black pepper Harvester”, a robot which autonomously navigates, harvests, and collects the peppercorn for further processing. Using efficient mechanical systems and smart image processing techniques. Our robot completes this cumbersome process economically, paving the way for better production on a global scale, as no such technology existed earlier.',
        '2018_concept': 'Farm tools that reduce drudgery and provide intelligent automation',
        '2018_info_2': 'We used the cascade lift mechanism for lifting the entire assembly, which uses a set of repetitive systems of links that assist in consecutive lifts, thus creating a cascade lift of the whole assembly. Our prototype was designed for a 3-stage cascade lift which is the most efficient mechanically. And to lift a horizontal platform on which pepper detection and cutting occur, we used a scissor mechanism. This process requires high precision and stability, which this mechanism achieves successfully.',
        '2018_info_3': 'This mechanism successfully achieves a claw mechanism used to precisely and accurately cut the pepper. It comprises a rack and pinion mechanism along with a leadscrew mechanism. The claw mechanism is fixed above the scissor mechanism. The web camera detects the pepper and calculates its location using image processing and ultrasonic.',
        '2018_info_4': 'Color detection was used here to select the type of peppercorn. Generally, at the time of maturation, the peppercorn develops a dark green color and is plucked when one of the pepper seeds in the peppercorn turns red. In image processing, the Gabor filter was used for texture analysis, which analyzes whether there is any specific frequency content in the image in specific directions in a localized region around the point or region of analysis.'
    },
    'cogni': {
        'head': 'Cognizance',
        'date': '2019, 2018',
        'about': 'Cognizance is the Annual Technical Festival of IIT Roorkee, comprising over 180 events that provide an excellent opportunity for participants to learn and explore. Commenced in 2003, cognizance emerged as one of the largest festivals of its kind in the Indian subcontinent. Many of its core events witnessed a participation numbering in thousands. Previously our group has participated in Cyborg break-in, Poseidon, Aeronove, and organized Armageddon.',
        '2019_head': 'Cyborg  break-in, 2019',
        '2019_info': 'Every year they released a problem statement, and teams across the nation participated in this competition. We secured the 3rd position in 2019. The task needed two robots, one manual robot, and one autonomous robot. They need to coordinate with each other to complete the game. The manual had to complete a set of tasks in order to cross checkpoints and score points, like cross all the hurdles, pick and drop the block, pick up the flag, and put the flag in the opponent\'s zone.  The autonomous bot shall move on a specified path provided it carries the cube, which shall be center exhibited, passed onto the manual bot.',
        '2019_info_2': 'Talking about the design, the manual robot was driven by a four-wheeled mecanum drive controlled (manually) by a remote. The manual Robot was also equipped with a gripper with two degrees of freedom that would lift a block of dimensions 2 X 2 X 2 inches. The autonomous robot had a microcontroller, line array sensors, and a stationary gripper at the front end. It was a two-wheel differential drive and autonomous.',
        '2018_head': 'Poseidon',
        '2018_info': 'The ASME IIT Roorkee Chapter took part in Poseidon, a technical event in Cognizance. The objective of the event was to design and manufacture a motorboat, which has to compete in the arena where the medium of travel is water. The task was to negotiate the track length in the minimum possible time. We secured 3rd position in 2018.',
        '2018_2_head': 'Armageddon',
        '2018_2_info': 'ASME IIT Roorkee Chapter is the organizer of “ARMAGEDDON”, one of the centrestage events of IIT Roorkee’s annual technical festival - Cognizance. It is a robowar event where bots of various teams lock their horns against each other to prove their mettle with the best one surviving in the end. It is one of the largest of its kind in India.'
    },
    'IRC': {
        'head': 'International Robotics Challenge (IRC)',
        'date': '2017',
        'about': 'Every year IRC (International Robotics Challenge) presents itself with challenging problem statements making the team explore new areas of robotics. It involves the fabrication of 2 types of bots: Manual and Autonomous, which would operate in coordination with each other to complete various challenging tasks. ASME IIT Roorkee Chapter won the 2nd place in the national level and 3rd place in the international level at IRC 2017.'
    },
    'Shrishti': {
        'head': 'Shrishti',
        'date': '2019',
        'about': 'Shrishti is the Annual Technical Exhibition of IIT Roorkee that aims towards integrating all realms of technical activities conducted within the IIT Roorkee. In Shrishti 2019, we exhibited SDC bot and HPV. We also participated in the Hovercraft event.'
    },
    'Chanakya': {
        'head': 'Chanakya Fellowship',
        'date': '2021',
        'about': 'Three of our members were selected for the iHUB Divyasampark Chanakya UG fellowship set up under the National Mission on Cyber-Physical Systems by the Department of Science and Technology, Govt. of India. They are working on Deep Sea Exploration with AUV (Autonomous Underwater Vehicle).'
    }
}
export const aboutdata = {
    'heading_1': 'WHO ARE WE?',
    'heading_2': 'About ASME',
    'para_1': 'ASME IIT Roorkee Chapter, the first and leading chapter of ASME in India, was officially registered as a society in Feb 2007 by some enthusiastic students of the Mechanical & Industrial Engineering Department (MIED). Our mission is to promote and enhance our members’ technical competency and professional well-being through quality programs and activities in mechanical engineering.',
    'para_2': 'We intend to organize regular competitions, industrial field trips, guest lectures, and interactive events. It enables skills development, career enrichment, and knowledge sharing. Over the past few years, we have conducted many workshops like Introduction to robotics, microcontrollers and microprocessors, image processing in robotics, PCB designing, and CAD using SolidWorks.',
    'para_3': 'ASME IIT Roorkee Chapter is the only club in IIT Roorkee which is aimed to promote underwater robotics. The team made its debut. Since then, the team has participated in competitions held by Flipkart- Grid 2.0- 2020, ASME E-Fest: HPVC- 2017, 2018, 2019, 2020, SDC- 2019, 2020. Continuing the legacy, the team participated remotely in ASME E-Fest HPVC- 2021 due to the pandemic.',
    'para_4': 'Before the pandemic, every year, ASME IITR Chapter organized Armageddon, a robotic battle event in Cognizance.',
    'para_5': 'Led by one faculty advisor, the team now consists of more than 50 members comprising not just the Mechanical and Electrical division but the Creatives team also who present our work to you all.',
    'para_6': 'ASME, which stands for American Society of Mechanical Engineers, is a non-profit membership organization. ASME serves a wide-ranging engineering community through quality learning, the development of codes and standards, certifications, research, conferences and publications, government relations, and other forms of outreach.',
    'para_7': 'ASME helps the global engineering community develop solutions to real-world challenges. Founded in 1880 as the American Society of Mechanical Engineers, ASME is a not-for-profit professional organization that enables collaboration, knowledge sharing, and skill development across all engineering disciplines while promoting the vital role of the engineer in society with over a hundred thousand members in 140+ countries. ASME hosts a number of conferences and workshops throughout the year, which provides a platform for like-minded individuals to come together and share their knowledge and experiences with others.'
}
export const eventsdata = {
    'heading_1': 'Lectures',
    'heading_2': 'Workshops',
    'heading_3': 'Online Quizzing Series',
    'heading_4': 'Informational Post Series',
    'heading_5': 'On the Spot Events',
    'para_1': 'A number of introductory robotics lectures are conducted for the students which cover topics like Mechanical Aspects, Drives, Motors, Gears, Sensors, Intro to Arduino Programming etc. The students are regularly examined and tested on the basis of the topics covered in the lectures. The lectures are aimed at giving them a fair idea about the vast world of Robotics and the amazing events and activities they can participate in being a part of ASME. Through attending the lectures the students acquaint themselves with the necessary theoretical knowledge to get a head start in the field of Robotics.',
    'para_2': 'Since lectures are not sufficient in imparting technical knowledge to the students, hence we organize workshops on different topics so that the students have practical knowledge and are able to grasp things that they are learning.',
    'para_3': 'During lockdown due to COVID-19, we conducted a series of 3 online quizzes. Around 80 students turned up for each of the quizzes. Participants were supposed to answer a set of questions in a given time limit. Topics of quizzes were general knowledge based so that more and more students could participate. Rewards were announced for top 3 performers.',
    'para_4': 'A series of posts were posted on our social media handles, Facebook and Instagram, to spread awareness about ongoing innovations all across the world. Images and videos, both were designed in-house by the graphics design team, while editing was done by the editorial team. The series received positive responses from the audience. It was observed that video posts attract more attention as compared to their counterparts. On a similar line, we will be introducing our blog on Medium very soon to share recent innovations/technology trends in even greater detail.',
    'para_5': 'With the aim of introducing first yearites to the technical stuffs, we came up with on-the-spot events where the students are required to make some gadgets with the limited materials made available to them. There were three events conducted in the past 5 years having a gap of 3 weeks between them.'
}
export const workshopsdata = {
    'heading_1': 'Workshops',
    'sub_heading_1': 'CAD using Solidworks',
    'sub_heading_2': 'Line Following Workshop',
    'sub_heading_3': 'Manual robot Workshop',
    'sub_heading_4': 'Workshop on sensors',
    'para_1_1': 'For an engineer, designing and simulation softwares are one of the most useful tools which define his ability and speciality in his field. Solidworks is one of the leading designing and simulation software available in the market for engineers. So, with the aim of making our future engineers good, we conducted a 2-day long workshop on ‘CAD using Solidworks’.',
    'para_1_2': 'It comprised 4 sessions of 3 hrs each. Students were provided with hands-on training in various domains like Part making, Assembly modelling, Sheet metal design and Motion study. This workshop was attended by 115+ students from UG, PG and PhD scholars from various departments and certificates were provided on completion. The event concluded with a 45min long ‘CAD Battle’ in which students competed amongst themselves to make a 3D model from a visual of a mechanical tool.',
    'para_2_1': 'One of the most basic applications of autonomous robots is the line following robot. Students were given a chance to build a line following bot on their own which provided them hands-on experience on design, fabrication, connection of sensors, Tinkercad and Arduino programming.',
    'para_3_1': 'A workshop dedicated to the exhibition and working of manual robots made by the team was organized. Students got a chance to operate the bots and their queries and doubts regarding design, fabrication and other aspects were gladly entertained.',
    'para_4_1': 'An all-day-long workshop was conducted in the Robotics Section of Hobbies Club, aimed to make the students aware of the different types of sensors that are used by robots to accomplish various tasks.',

}
export const lecturedata = {
    'heading_1': 'Lectures',
    'para_1': 'A number of introductory robotics lectures are conducted for the students which cover topics like Mechanical Aspects, Drives, Motors, Gears, Sensors, Intro to Arduino Programming etc. The students are regularly examined and tested on the basis of the topics covered in the lectures. The lectures are aimed at giving them a fair idea about the vast world of Robotics and the amazing events and activities they can participate in being a part of ASME. Through attending the lectures the students acquaint themselves with the necessary theoretical knowledge to get a head start in the field of Robotics.',
}
export const spotdata = {
    'heading_1': 'On The Spot',
    'sub_heading_1': 'Obelisk',
    'sub_heading_2': 'Amphibian',
    'sub_heading_3': 'Chevalet',
    'para_1_1': 'OBELISK, in which the students were required to build a tower like structure with, given materials (Index card, Pins, Thread, Cutter) that can have maximum possible height and can sustain maximum possible vertical load (weight).',
    'para_2_1': 'AMPHIBIAN, in which the students were required to make a bot which had to prove its mobility in water as well as on land.',
    'para_3_1': 'CHEVALET was a suspension bridge making competition.'
}

export const advisor_data =[
    {
     "Timestamp": "1\/3\/2022 11:12:23",
     "name": "Dr V. Huzur Saran",
     "Picture": p40,
     "POR": "Faculty Advisor",
     "email": "v.saran@me.iitr.ac.in",
     "linkedIN": "(Faculty Profile Link ) https:\/\/www.iitr.ac.in\/~ME\/Saran__V_H_",
     "insta": "NA"
    },

]
export const management_data = [
    {
     "Timestamp": "12\/29\/2021 18:33:32",
     "name": "Bharat Singh Rajpurohit",
     "Picture": p10,
     "POR": "Chairperson",
     "email": "brajpurohit@me.iitr.ac.in",
     "linkedIN": "https:\/\/in.linkedin.com\/in\/bharat-singh-rajpurohit-457190173",
     "insta": "https:\/\/www.instagram.com\/bharat_s_raj\/"
    },
    {
     "Timestamp": "12\/29\/2021 22:36:46",
     "name": "Soumyajeet Jaiswal",
     "Picture": p36,
     "POR": "Vice chairperson",
     "email": "sjaiswal2@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/soumyajeetjaiswal\/",
     "insta": "https:\/\/www.instagram.com\/soumyajeet_jaiswal\/"
    },
    {
     "Timestamp": null,
     "name": "Madhukar",
     "Picture": p25,
     "POR": "Technical Mentor",
     "email": "madhukar@ec.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/madhukar18\/",
     "insta": "https:\/\/www.instagram.com\/madhukar.98\/"
    },
    {
     "Timestamp": null,
     "name": "Putluru Deshik Reddy",
     "Picture": p15,
     "POR": "Technical Mentor",
     "email": "preddy@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/deshik-reddy-putluru\/",
     "insta": "https:\/\/www.instagram.com\/deshikreddy_\/"
    },
    {
     "Timestamp": "2\/7\/2022 18:00:42",
     "name": "Tamanna Rami",
     "Picture": p38,
     "POR": "Technical Mentor",
     "email": "trami@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/tamanna-rami\/",
     "insta": "https:\/\/www.instagram.com\/raami_tam\/"
    },
    {
     "Timestamp": "12\/29\/2021 20:52:17",
     "name": "Kartikeya Rai",
     "Picture": p23,
     "POR": "Additional Secretary (Creatives)",
     "email": "krai@ar.iitr.ac.in",
     "linkedIN": "NA",
     "insta": "https:\/\/www.instagram.com\/kartikeyarai\/"
    },
    {
     "Timestamp": "12\/29\/2021 23:12:33",
     "name": "Kishita Dhargawe",
     "Picture": p17,
     "POR": "Secretary",
     "email": "dhargawe_k@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/kishita-dhargawe\/",
     "insta": "https:\/\/www.instagram.com\/kishita_d\/"
    },
    {
     "Timestamp": "12\/29\/2021 16:25:11",
     "name": "Tarale Janhavi Anilkumar",
     "Picture": p22,
     "POR": "Joint Secretary",
     "email": "tarale_j@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/janhavi-tarale-703502194",
     "insta": "https:\/\/instagram.com\/janhavi_729?utm_medium=copy_link"
    },
    {
     "Timestamp": "12\/29\/2021 22:39:51",
     "name": "Shrashtika Singh",
     "Picture": p35,
     "POR": "Technical Head",
     "email": "shrashtika_s@ee.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/shrashtika-singh-4b2360191\/",
     "insta": "https:\/\/www.instagram.com\/shrashti_ka"
    },
    {
     "Timestamp": "12\/29\/2021 23:09:52",
     "name": "Aditya Raj Singh Gour",
     "Picture": p2,
     "POR": "Joint Secretary",
     "email": "aditya_r@ec.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/aditya-raj-singh-gour-8438a6179",
     "insta": "https:\/\/www.linkedin.com\/redir\/redirect?url=https%3A%2F%2Fwww%2Einstagram%2Ecom%2F__shadow__17%2F&urlhash=5PNs"
    },
    {
     "Timestamp": "12\/29\/2021 16:35:33",
     "name": "Bhavna",
     "Picture": p12,
     "POR": "Technical Head ",
     "email": "Bhavna_m@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/bhavna-bhavna-543337173",
     "insta": "https:\/\/instagram.com\/b_h_a_v_n_a1?utm_medium=copy_link"
    },
    {
     "Timestamp": null,
     "name": "Mayank Kumar",
     "Picture": p27,
     "POR": "Content Head",
     "email": "mayank_k@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/mahiya30",
     "insta": "https:\/\/www.instagram.com\/mahiya_30\/"
    },
    {
     "Timestamp": null,
     "name": "Prajjawal Sahu",
     "Picture": p33,
     "POR": "WebD Head (Front-End)",
     "email": "prajjawal_s@es.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/prajjawal-sahu-7372791b5\/",
     "insta": "https:\/\/www.instagram.com\/prajjawalsahu\/"
    },
    {
     "Timestamp": "2\/7\/2022 6:57:55",
     "name": "Yash Srivastava",
     "Picture": p41,
     "POR": "WebD Head (Back-End)",
     "email": "yash_s@ph.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/yash-srivastava-826aa21a0\/",
     "insta": "https:\/\/www.instagram.com\/me__yash\/"
    },
    {
     "Timestamp": "2\/8\/2022 0:15:30",
     "name": "Bhavesh Shamnani",
     "Picture": p11,
     "POR": "Senior executive member",
     "email": "bhavesh_c@me.iitr.ac.in",
     "linkedIN": "https:\/\/in.linkedin.com\/in\/bhavesh-shamnani",
     "insta": "https:\/\/www.instagram.com\/bhavesh_16.12\/"
    },
    {
     "Timestamp": null,
     "name": "Omesh Kumawat",
     "Picture": p31,
     "POR": "Design-Head",
     "email": "omesh_k@ar.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/omesh-kumawat-91a778195",
     "insta": "https:\/\/www.instagram.com\/ok_omg_7_\/"
    },
    {
     "Timestamp": null,
     "name": "Sakshi Gupta",
     "Picture": p34,
     "POR": "Events and Outreach Manager",
     "email": "Sakshi_g@mt.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/sakshiguptaiitr",
     "insta": "https:\/\/instagram.com\/_saks.hi?utm_medium=copy_link"
    },
    {
     "Timestamp": "12\/29\/2021 23:30:45",
     "name": "Dushyant Yadav",
     "Picture": p19,
     "POR": "Senior executive member",
     "email": "Dushyant_y@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/dushyant-yadav-27ab451a6",
     "insta": "https:\/\/instagram.com\/dushyant.yadav.95?utm_medium=copy_link"
    },


]
export const executives_data = 
[
    {
     "Timestamp": "12\/29\/2021 15:55:34",
     "name": "Ayush Diwang",
     "Picture": p9,
     "POR": null,
     "email": "ayush_d@ce.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/ayush-diwang-4840511bb",
     "insta": "https:\/\/instagram.com\/_devraj_1012?utm_medium=copy_link"
    },
    {
     "Timestamp": "12\/29\/2021 16:09:33",
     "name": "Ankit Soni",
     "Picture": p6,
     "POR": null,
     "email": "Ankit_s@ece.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/ankit-soni-3a727821a",
     "insta": "https:\/\/www.instagram.com\/ankitsoni5911\/"
    },
    {
     "Timestamp": "12\/29\/2021 18:05:10",
     "name": "Deepak Sharma",
     "Picture": p14,
     "POR": null,
     "email": "deepak_s1@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/mwlite\/in\/deepak-sharma-40a8781b8",
     "insta": "https:\/\/www.instagram.com\/deep_sharma0505\/"
    },
    {
     "Timestamp": "12\/29\/2021 18:33:54",
     "name": "Nitish Kumar",
     "Picture": p29,
     "POR": null,
     "email": "nitish_k@mt.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/nitish-kumar-21a842214",
     "insta": "https:\/\/www.instagram.com\/nitish.kumar_68\/?fbclid=IwAR2d_LtH12XI9VEsvwqkrk_2Lc-bZTbF83a273TYmn0lz2rhcDcUlcq2Uww"
    },
    {
     "Timestamp": "12\/29\/2021 18:48:10",
     "name": "Srushti Giridhar Borate",
     "Picture": p13,
     "POR": null,
     "email": "borate_sg@ee.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/srushti-borate-b1000020a\/",
     "insta": "-"
    },
    {
     "Timestamp": "12\/29\/2021 19:20:40",
     "name": "Aditya Sen",
     "Picture": p3,
     "POR": null,
     "email": "aditya_s1@mt.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/aditya-sen-2241121b9",
     "insta": "https:\/\/www.instagram.com\/adityasen1606\/"
    },
    {
     "Timestamp": null,
     "name": "Aditya Gupta ",
     "Picture": p1,
     "POR": null,
     "email": "Aditya_g1@ece.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/aditya-gupta-48650b200",
     "insta": "https:\/\/www.instagram.com\/_gupta.aditya\/"
    },
    {
     "Timestamp": "12\/29\/2021 22:43:24",
     "name": "Pahul Singh Sawhney ",
     "Picture": p32,
     "POR": null,
     "email": "pahul_ss@mt.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/pahul-singh-sawhney-4787041b4",
     "insta": "https:\/\/www.instagram.com\/pahul_singh2002\/"
    },
    {
     "Timestamp": "12\/29\/2021 22:50:14",
     "name": "Uday Gupta",
     "Picture": p39,
     "POR": null,
     "email": "uday_g@ee.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/uday-gupta-02200020a",
     "insta": "https:\/\/instagram.com\/uday02gupta?utm_medium=copy_link"
    },
    {
     "Timestamp": "12\/29\/2021 15:34:24",
     "name": "Ashwin Korade",
     "Picture": p8,
     "POR": null,
     "email": "ashwin@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/ashwin-korade-7a7ba120a\/",
     "insta": "https:\/\/www.instagram.com\/ashwin101\/"
    },
    {
     "Timestamp": "12\/29\/2021 15:53:31",
     "name": "Mundhe Sushant Uttam",
     "Picture": p28,
     "POR": null,
     "email": "mundhe_su@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/mundhe-sushant-uttam-76a475202",
     "insta": "https:\/\/instagram.com\/sushantmundhe5?utm_medium=copy_link"
    },
    {
     "Timestamp": "12\/29\/2021 15:53:31",
     "name": "Aman Dhanotiya",
     "Picture": p4,
     "POR": null,
     "email": "aman_d@ce.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/aman-dhanotiya-2812361b9\/",
     "insta": "https:\/\/instagram.com\/dhanotiyaaman?utm_medium=copy_link"
    },
    {
     "Timestamp": "12\/29\/2021 22:52:24",
     "name": "Dhruv Kumar",
     "Picture": p18,
     "POR": null,
     "email": "dhruv_k@ch.iitr.ac.in",
     "linkedIN": "https:\/\/www.facebook.com\/dhruvkumar.satyarthi.9",
     "insta": "https:\/\/www.instagram.com\/dhruvsatyarthi2786\/"
    },
    {
     "Timestamp": "12\/29\/2021 23:02:15",
     "name": "Vaishnavi Gupta",
     "Picture": p42,
     "POR": null,
     "email": "vaishnavi_g@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/vaishnavi-gupta-02b208216",
     "insta": "https:\/\/www.instagram.com\/vaishnavigupta_18"
    },
    {
     "Timestamp": "12\/29\/2021 23:16:01",
     "name": "Amichand Gurjar",
     "Picture": p5,
     "POR": null,
     "email": "amichand_g@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/amichand-gurjar-668807213",
     "insta": "https:\/\/www.instagram.com\/a_g_mankash\/"
    },
    {
     "Timestamp": "12\/29\/2021 23:29:42",
     "name": "Nitesh morya",
     "Picture": p30,
     "POR": null,
     "email": "nitesh_m@ce.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/mwlite\/in\/nitesh-morya-856732218",
     "insta": "https:\/\/www.instagram.com\/invites\/contact\/?i=1vtncearm9mo&utm_content=lg9mk5v"
    },
    {
     "Timestamp": "12\/30\/2021 0:07:57",
     "name": "Dev Raj Jain",
     "Picture": p16,
     "POR": null,
     "email": "dev_rj@ece.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/dev-raj-jain-297014202",
     "insta": "https:\/\/instagram.com\/jain_devraj17?utm_medium=copy_link"
    },
    {
     "Timestamp": "12\/30\/2021 1:28:55",
     "name": "Hemant Naresh Sadawana",
     "Picture": p21,
     "POR": null,
     "email": "sadawana_hn@me.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/hemant-sadawana-650537229",
     "insta": "https:\/\/instagram.com\/explorer_hemant25?utm_medium=copy_link"
    },
    {
     "Timestamp": null,
     "name": "Lokendra singh parmar ",
     "Picture": p24,
     "POR": null,
     "email": "lokendra_sp@cs.iitr.ac.in",
     "linkedIN": "www.linkedin.com\/in\/lokendra-singh-parmar-13b628201",
     "insta": "https:\/\/www.instagram.com\/_.lokendra_\/"
    },
    {
     "Timestamp": "2\/7\/2022 15:55:44",
     "name": "Sugandha Gupta",
     "Picture": p37,
     "POR": null,
     "email": "sugandha_g@ce.iitr.ac.in ",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/sugandha-gupta-872ba9205",
     "insta": "https:\/\/www.instagram.com\/invites\/contact\/?i=1uxwt9jl25ems&utm_content=jtmqen2"
    },
    {
     "Timestamp": "2\/7\/2022 16:04:52",
     "name": "Harsh Raj Ambastha ",
     "Picture": p20,
     "POR": null,
     "email": "harsh_ra@ce.iitr.ac.in ",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/harsh-raj-ambastha-241a8a201",
     "insta": "https:\/\/instagram.com\/harsh.raj.ambastha?utm_medium=copy_link"
    },
    {
     "Timestamp": "2\/7\/2022 16:13:07",
     "name": "Ashwani Kumar ",
     "Picture": p7,
     "POR": null,
     "email": "ashwani_k@bt.iitr.ac.in ",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/ashwani-kumar-2a7978202",
     "insta": "https:\/\/www.instagram.com\/ashwani8409kumar\/"
    },
    {
     "Timestamp": null,
     "name": "Sarveshwar Pandey",
     "Picture": p43,
     "POR": null,
     "email": "sarveshwar_p@me.iitr.ac.in",
     "linkedIN": "www.linkedin.com\/in\/sarveshwar-pandey-9010871b8",
     "insta": "https:\/\/www.instagram.com\/sarveshwar.pandey0805\/"
    },
    {
     "Timestamp": "2\/8\/2022 19:51:22",
     "name": "Mansi paliwal",
     "Picture": p26,
     "POR": null,
     "email": "mansi_p@ce.iitr.ac.in",
     "linkedIN": "https:\/\/www.linkedin.com\/in\/mansi-paliwal-a635b6215",
     "insta": "https:\/\/www.instagram.com\/invites\/contact\/?i=3n9cjcebhlgx&utm_content=lfa3ug8"
    }
   ]
