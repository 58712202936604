import React, { Component } from 'react'
import './events.css';
import gears from '../../assets/icons/gears.svg';
import { spotdata } from '../../maindata';
import spot1 from '../../assets/images/spot1.jpg';
import spot2 from '../../assets/images/spot2.jpg';
import spot3 from '../../assets/images/spot3.jpg';



const Spot = (props) => {
    return (
        <React.Fragment>
        <div className="about_style">

            <h1>{spotdata['heading_1']}</h1>

            <img className="event_gear_prop" src={gears} />

            <h2>{spotdata['sub_heading_1']}</h2>
            <div className="event_content event_content_spot">
                <div className="left_content">
                    <p className="main_content">{spotdata['para_1_1']}</p>
                    <p className="main_content">{spotdata['para_1_2']}</p>
                    <h2>{spotdata['sub_heading_2']}</h2>
                    <p className="main_content">{spotdata['para_2_1']}</p>
                    <h2>{spotdata['sub_heading_3']}</h2>
                    <p className="main_content">{spotdata['para_3_1']}</p>
                </div>
                <img class="event_prop" src={spot1} alt=""/>
            </div><br />


            

        </div>
        </React.Fragment>
    )
}

export default Spot