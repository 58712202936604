import React from 'react'
import '../../Events/events.css';
import './HPVC.css'
import gears from '../../../assets/icons/gears.svg';
import { maindata } from '../../../maindata';
import hpvc_1 from '../../../assets/images/HPVC.png';
import hpvc_2021_1 from '../../../assets/images/hpvc_2021_1.png';
import hpvc_2021_2 from '../../../assets/images/hpvc_2021_2.png';

function HPVC() {
    return (
        <React.Fragment>
        <div className="about_style">
            <h1>HPVC</h1>

            <img className="event_gear_prop HPVC" src={gears} />

            <p className="main_content">{ maindata['HPVC']['about'] }</p>

            <h2>{ maindata['HPVC']['2021_head'] }</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{ maindata['HPVC']['2021_info'] }</p>
            <div className="card">
            <p className="card_head">Main Idea/ Concept</p>
            <p className="card_content">{ maindata['HPVC']['2021_concept'] }</p>
            </div>
            <p className="main_content">{ maindata['HPVC']['2021_info_2'] }</p>
            <p className="main_content">{ maindata['HPVC']['2021_info_3'] }</p>
            <p className="main_content">{ maindata['HPVC']['2021_info_4'] }</p>
            </div>
            <div className="right_content">
                <img class="sample_prop hpvc_2020" src={hpvc_2021_1} alt=""/>
                <img class="sample_prop hpvc_2020" src={hpvc_2021_2} alt=""/>
            </div>
            </div><br />

            <h2>{ maindata['HPVC']['2020_head'] }</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{ maindata['HPVC']['2020_info'] }</p>
            </div>
            <div className="right_content">
                <img class="sample_prop hpvc_2020" src={hpvc_1} alt=""/>
            </div>
            </div><br />

        </div>
        </React.Fragment>
    )
}

export default HPVC