import React from 'react'
import '../../Events/events.css';
import './IRC.css'
import gears from '../../../assets/icons/gears.svg';
import { maindata } from '../../../maindata';
import irc_1 from '../../../assets/images/IRC.png';
import irc_2 from '../../../assets/images/IRC_2.png';

function IRC() {
    return (
        <React.Fragment>
        <div className="about_style">
            <h1>IRC</h1>

            <img className="event_gear_prop IRC" src={gears} />

            <p className="main_content">{ maindata['IRC']['about'] }</p><br />
            <div className='irc_image'>
                <img class="irc_2017" src={irc_1} alt=""/>
                <img class="irc_2017" src={irc_2} alt=""/>
            </div>
        </div>
        </React.Fragment>
    )
}

export default IRC