import React from 'react'
import '../../Events/events.css';
import './SAUVC.css'
import gears from '../../../assets/icons/gears.svg';
import { maindata } from '../../../maindata';
import sauvc_1 from '../../../assets/images/SAUVC.png';
import sauvc_2022_1 from '../../../assets/images/sauvc_2022_1.png';

function SAUVC() {
    return (
        <React.Fragment>
        <div className="about_style">
            <h1>SAUVC</h1>

            <img className="event_gear_prop SAUVC" src={gears} />

            <p className="main_content">{ maindata['SAUVC']['about'] }</p>

            <h2>{ maindata['SAUVC']['2022_head'] }</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{ maindata['SAUVC']['2022_info'] }</p>
            <div className="card">
            <p className="card_head">Main Idea/ Concept</p>
            <p className="card_content">{ maindata['SAUVC']['2022_concept'] }</p>
            </div>
            <p className="main_content">{ maindata['SAUVC']['2022_info_2'] }</p>
            <p className="main_content">{ maindata['SAUVC']['2022_info_3'] }</p>
            </div>
            <div className="right_content">
                <img class="sample_prop sauvc_2022" src={sauvc_1} alt=""/>
                <img class="sample_prop sauvc_2022" src={sauvc_2022_1} alt=""/>
            </div>
            </div><br />
        </div>
        </React.Fragment>
    )
}

export default SAUVC