import React from 'react'
import './eventpage.css'
import gears from '../../assets/icons/gears.svg';
import Eventcard from '../EventCard';
import { maindata } from '../../maindata';
import sample from '../../assets/images/sample.png'
import { eventsdata } from '../../maindata';
import lec1 from '../../assets/images/lec1.jpg';
import work1 from '../../assets/images/work1.jpg';
import spot1 from '../../assets/images/spot1.jpg';


function EventPage() {
    return (
        <React.Fragment>
            <div className="about_style">
                <h1>Events</h1>
                <img className="gear_prop eventpage_gear" src={gears} />
                <Eventcard 
                    event_name={eventsdata['heading_1']}
                    event_date="22nd July 2021"
                    event_des={eventsdata['para_1']}
                    event_img={lec1}
                    name="events/lecture"
                />
                <Eventcard 
                    event_name={eventsdata['heading_2']}
                    event_date="22nd July 2021"
                    event_des={eventsdata['para_2']}
                    event_img={work1}
                    name="events/workshops"
                />
                <Eventcard 
                    event_name={eventsdata['heading_5']}
                    event_date="22nd July 2021"
                    event_des={eventsdata['para_5']}
                    event_img={spot1}
                    name="events/on_the_spot"
                />
                <Eventcard 
                    event_name={eventsdata['heading_3']}
                    event_date="22nd July 2021"
                    event_des={eventsdata['para_3']}
                />
                <Eventcard 
                    event_name={eventsdata['heading_4']}
                    event_date="22nd July 2021"
                    event_des={eventsdata['para_4']}
                />
            </div>
        </React.Fragment>
    )
}

export default EventPage
