import React from 'react'
import './resources.css'
import gears from '../../assets/icons/gears.svg';

function Resources() {
  return (
    <div>
        <div className="about_style">
            <h1>Resources</h1>
            <img className="gear_prop resources" src={gears} />
        </div>
        <div className='resources_link'>
            <a href='https://drive.google.com/file/d/1ly5eK99MoPbie4r75eIYO7W06i1Iy0jp/view?usp=sharing' target="_blank">Microcontrollers and Sensors Workshop</a>
            <a href='https://drive.google.com/file/d/15LwqDg_N_-S0fGkATwnd7IIPuHYLRpyX/view?usp=sharing' target="_blank">Introdunction to Robotics Workshop</a>
            <a href='https://drive.google.com/file/d/1A1lJqJH6_vBPVHPU0SPyRkWCuvpFbYEy/view?usp=sharing' target="_blank">HPVC 2020 Report</a>
            <a href='https://drive.google.com/file/d/1DKahQ0rJ87LVCvVFveOA8ybpvnfNLbL0/view?usp=sharing' target="_blank">HPVC 2021 Design Report</a>
            <a href='https://drive.google.com/file/d/1Fqdc7u-c8RK5b6j0pOWJCI3J4cDTNbF8/view?usp=sharing' target="_blank">HPVC 2021 Innovation Report</a>
            <a href='https://drive.google.com/file/d/1H2F9sCJLlUrh5Dg2CHxQLiKYBRmiGEQx/view?usp=sharing' target="_blank">Intro Talk 2022</a>
        </div>
    </div>
  )
}

export default Resources;